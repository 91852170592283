(function ($) {
  Drupal.behaviors.countryChooserFormatterV1 = {
    attach: function (context) {
      var $template = $('.js-gnav-country-chooser-formatter', context);

      $template.each(function () {
        var $self = $(this);
        var $selectBox = $('.js-gnav-country-chooser-select', $self);

        $selectBox.selectBox({
          keepInViewport: true
        });
        $selectBox.change(function () {
          var $selected = $(this).find(':selected');

          if ($selected.data('url')) {
            window.location = $selected.data('url');
          }
        });
      });
    }
  };
})(jQuery);
